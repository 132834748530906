Epoxy Coating Problem solving guide - compiled by flooring champion Kishore Hoysal S Copy
<template>
    <div>
      <h2>Epoxy Coating Problem solving guide - compiled by flooring champion Kishore Hoysal S Copy</h2>
      <iframe
        src="/files/EpoxyCoating.pdf"
        width="100%"
        height="600px"
        style="border: none;">
      </iframe>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EpoxyGuide',
  };
  </script>
  
  <style scoped>
  /* Optionally, you can adjust the styling here */
  </style>
  