<template>
    <div>
      <h2>Resin Floor Selection</h2>
      <a href="/files/SelectionCharts.xlsx" target="_blank">Download Resin Floor Selection Excel</a>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ResinFloorSelection',
  };
  </script>
  