<template>
    <div>
      <h2>Water Proofing Selection</h2>
      <a href="/files/WaterProofingSelection.xlsx" target="_blank">Download WaterProofing Selection Excel</a>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ResinFloorSelection',
  };
  </script>
  