<template>
  <div id="app">
    <nav>
      <ul>
        <li><router-link to="/">Home</router-link></li>
        
        <li><router-link to="/resin-floor-selection">Water Proofing Selection</router-link></li>
        <li><router-link to="/Epoxy-Coating-Guide">Epoxy Coating Guide</router-link></li>
        <li><router-link to="/Rating-Factor">Hoysala's Cube R Rating Index</router-link></li>
        <li><router-link to="/Selection-Chart">Resin Floor Selection</router-link></li>

       
      </ul>
    </nav>

    <router-view />

    <footer>
      <p>
        <i class="fas fa-envelope"></i> Contact us: <a href="mailto:hetchtoo@gmail.com">hetchtoo@gmail.com</a> 
        | <i class="fas fa-phone-alt"></i> Phone: 9989140055
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import '@fortawesome/fontawesome-free/css/all.css';

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  background-color: #333;
}
nav ul li {
  margin-right: 20px;
}
footer a {
  color: white;
  text-decoration: none;
}
nav ul li a {
  color: white;
  text-decoration: none;
  padding: 10px;
  display: block;
}
nav ul li a:hover {
  background-color: #555;
}
footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
}
</style>
