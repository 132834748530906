<template>
    <div>
      <h2>Hoysala's Cube R Rating Index</h2>
      <iframe
        src="/files/Hoysala Cube R Rating Index - A management Tool - Kishore Hoysal S dt 7 9 2024.pdf"
        width="100%"
        height="600px"
        style="border: none;">
      </iframe>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HoysalaRatingFactor',
  };
  </script>
  
  <style scoped>
  /* Optionally, you can adjust the styling here */
  </style>
  