import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';

import ResinFloorSelection from '@/components/ResinFloorSelection.vue';
import EpoxyGuide from '@/components/EpoxyGuide.vue';
import RatingIndex from '@/components/RatingIndex.vue';
import SelectionChart from '@/components/SelectionChart';


const routes = [
  { path: '/', component: HomePage },
  
  { path: '/resin-floor-selection', component: ResinFloorSelection },
  {path:'/Epoxy-Coating-Guide',component:EpoxyGuide},
  {path:'/Rating-Factor',component:RatingIndex},
  {path:'/Selection-Chart', component:SelectionChart}

  

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
