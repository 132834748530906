<template>
  <div class="home-container">
    <img src="@/assets/kishore.png" alt="Resin Floor" class="centered-image" />
    <div class="content">
      <h2>Welcome to resin-hoysal.com</h2>
      <p>
        We will provide you basic details on the selection of Resin Flooring System in line with FeRFA guidelines.
        Please also go through my latest paper on Certification of Resin Floors through the rating concept, first of its kind in our Industry.
      </p>
      <p>
        Share your comments at <a href="mailto:hetchtoo@gmail.com">hetchtoo@gmail.com</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style scoped>
.home-container {
  text-align: center;
  margin-top: 20px;
}

.centered-image {
  width: 300px; /* Adjust the width to make the image smaller */
  height: auto;
  margin: 0 auto;
}

.content {
  margin-top: 20px;
  font-size: 1.1rem;
  line-height: 1.5;
}
</style>
